import React from "react";
import "./Skills.css";
import skills from "../../data/skills.json";
import { motion } from "framer-motion";

const Skills = () => {
  return (
    <section id="skills" className="skills-section">
      <div className="skills-container">
        <h1 className="skills-heading">Skills & Technologies</h1>
        <div className="skills-grid">
          {skills.map((skill, idx) => (
            <motion.div
              initial={{
                opacity: 0,
                translateY: "20px",
              }}
              whileInView={{
                opacity: 1,
                translateY: 0,
              }}
              viewport={{ once: true }}
              transition={{
                delay: 0.3 + (idx % 4) * 0.2,
                duration: 0.4,
              }}
              className="skills-card" key={skill.id}>
              <a href={skill.link} target="_blank" rel="noopener noreferrer">
                <img src={skill.img} alt={skill.title} className="skill-image" />
                <h2 className="skill-title">{skill.title}</h2>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Skills;