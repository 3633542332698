import React from "react";
import "./About.css";

export default function About() {
  return (
    <section id="about" className="about-section">
      <div className="about-container">
        <div className="about-text-container">
          <h1 className="about-title">
            Hi, I'm Zhandos.
            <br />I love to build amazing apps.
          </h1>
          <p className="about-paragraph">
            Experienced full-stack web developer with expertise in TypeScript, JavaScript, Python, SQL, and more. Trained at 42 Silicon Valley and further educated at App Academy. Committed to delivering innovative solutions, I thrive on challenges and continuously explore new technologies. My passion for clean code, collaborative problem-solving, and user-centric design drives me to create exceptional web experiences. Excited to leverage my diverse skill set for meaningful projects and collaborations.
          </p>
          <div className="about-buttons">
            <a href="#projects" className="about-button see-past-work">
              See My Projects
            </a>
          </div>
        </div>
        <div className="about-image-container">
          <img className="about-image" alt="hero" src="./coding.svg" />
        </div>
      </div>
    </section>
  );
}
