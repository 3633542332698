import React from "react";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Skills from "./components/Skills";
import ContactLinks from "./components/ContactLinks";
import Projects from "./components/Projects";

function App() {
  return (
    <div
    >
        <ContactLinks />
        <Navbar />
        <About />
        <Skills />
        <Projects />
    </div>
  )
}

export default App;
